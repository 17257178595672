frappe.provide("erpnext.ProductGrid");

erpnext.ProductGrid = class extends erpnext.ProductGrid {
    make() {
		let me = this;
		let html = ``;
		this.items.forEach(item => {
			let title = item.web_item_name || item.item_name || item.item_code || "";
			title =  title.length > 90 ? title.substr(0, 90) + "..." : title;

			html += `<div class="col-sm-3 item-card"><div class="card text-left">`;
			// html += me.get_image_html(item, title);
			html += me.get_card_body_html(item, title, me.settings);
			html += `</div></div>`;
		});

		let $product_wrapper = this.products_section;
		$product_wrapper.append(html);
	}
	get_card_body_html(item, title, settings) {
		let body_html = `
			<div class="card-body text-left card-body-flex" style="width:100%; padding-top:3em;">
				<div style="margin-top: 1rem; display: flex;">
		`;
		body_html += this.get_title(item, title);

		// get floating elements
		if (!item.has_variants) {
			if (settings.enable_wishlist) {
				body_html += this.get_wishlist_icon(item);
			}
			if (settings.enabled) {
				body_html += this.get_cart_indicator(item);
			}

		}

		body_html += `</div>`;
		body_html += `<div class="product-category">${ item.item_group || '' }</div>`;

		// load price html even is not precent, to retain card layout
		// if (item.formatted_price) {
		body_html += this.get_price_html(item);
		// }

		body_html += this.get_stock_availability(item, settings);
		body_html += this.get_primary_button(item, settings);
		body_html += `</div>`; // close div on line 49

		return body_html;
	}

	get_title(item, title) {
		let title_html = `
			<a href="/${ item.route || '#' }" style="color: var(--gray-800); font-weight: 500;">
				<div class="product-title-js">
					${ title || '' }
				</div>
			</a>
		`;
		return title_html;
	}
};