frappe.provide("erpnext.ProductList");
erpnext.ProductList = class extends erpnext.ProductList {
    make() {
		let me = this;
		let html = `<br><br>`;
		this.items.forEach(item => {
			let title = item.web_item_name || item.item_name || item.item_code || "";
			title =  title.length > 200 ? title.substr(0, 200) + "..." : title;

			html += `<div class='row list-row w-100 mb-4'>`;
			// html += me.get_image_html(item, title, me.settings);
			html += me.get_row_body_html(item, title, me.settings);
			html += `</div>`;
		});

		let $product_wrapper = this.products_section;
		$product_wrapper.append(html);
	}

	get_row_body_html(item, title, settings) {
		let body_html = `<div class='col-12 text-left' style='width:100%; padding-left:4em;'>`;
		body_html += this.get_title_html(item, title, settings);
		body_html += this.get_item_details(item, settings);
		body_html += settings.enable_wishlist ? this.get_wishlist_icon(item): '';
		body_html += `</div>`;
		return body_html;
	}
};